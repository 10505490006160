import React from "react";
import ProfileName from "./profile-name/ProfileName";
import ProfileData from "./profile-data/ProfileData";
import { Link } from "react-router-dom";
import SavePostVideos from "./save-post-video/SavePostVideos";

const Profile = () => {
  return (
    <>
      <div className="profileInfo  p-100">
        <ProfileName />
        <ProfileData />
        <div className="desc">
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus debitis, animi nobis quaerat facere dicta,</p>
        </div>
        <div className="copyText">
            <p>Flowtime.com/dfs</p>
            <i className="ri-file-copy-fill"></i>
        </div>
        <div className="innerPageBox">
            <div className="pageBtn">
                <Link to={"/edit-profile"}>Edit Profile</Link>
                <Link to={"#"}>Share Profile</Link>
            </div>
            <div className="suggestIcon">
            <i className="ri-user-add-line"></i>
            </div>
        </div>
        <SavePostVideos />
      </div>
    </>
  );
};

export default Profile;
