import React from 'react'
import Profile from '../../components/profile/Profile'

const ProfilePage = () => {
  return (
    <>
        <Profile />
    </>
  )
}

export default ProfilePage