import React from "react";
import select1 from "../../../assets/img/create/select1.png";

const UploadPost = () => {
  return (
    <>
      <div className="uploadPostSec">
        <div className="sectionTitle">
          <h2>review & Upload</h2>
        </div>

        <div className="postInfo">
          <div className="postFigureCard">
            <div className="multiSelectCard">
              <img src={select1} alt="Post" className="img-fluid" />
              <div className="removeIcon">
                <i className="ri-close-line"></i>
              </div>
            </div>
          </div>
          <div className="quotesArea">
            <h4>Mind is beautiful too</h4>
            <textarea
              maxLength="200"
              cols="30"
              rows="10"
              placeholder="Say Something..."
              className="form-control"
            ></textarea>
          </div>
          <div className="trendName">
            <h4># trends name</h4>
            <ul>
              <li> #Post </li>
              <li> #Nature </li>
              <li> #Click </li>
              <li> #Beauty </li>
              <li> #Trend </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadPost;
