import React from 'react'
import UserProfile from '../../components/user-profile/UserProfile'

const UserProfilePage = () => {
  return (
    <>
        <UserProfile />
    </>
  )
}

export default UserProfilePage