import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import CreatePostModal from "./create-post-modal/CreatePostModal";

const Menus = ({ data }) => {
  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isCross, setIsCross] = useState(false);
  
  
  const {
    setActiveSearch,
    isActiveSearch,
    setActiveMessage,
    isActiveMessage,
    isActiveNotify,
    setActiveNotify,
  } = data;

  // Theme Color
  const [theme, setTheme] = useState(false);

  const handleTheme = () => {
    document.body.classList.toggle("dark-mode");
    setTheme(true);
  };

  return (
    <>
      <div className="menusSec">
        <ul>
          <li>
            <NavLink to={"/"}>
              <i className="ri-home-7-line"></i>
              <span className="title">Home</span>
            </NavLink>
          </li>
          <li>
            <button
              type="button"
              onClick={() => setActiveSearch(!isActiveSearch)}
            >
              <i className="ri-search-line"></i>
              <span className="title">Search</span>
            </button>
          </li>
          <li>
            <NavLink to={"/nearby"}>
              <i className="ri-map-pin-line"></i>
              <span className="title">Nearby</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/follow"}>
              <i className="ri-user-follow-line"></i>
              <span className="title">Follow</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/reels"}>
              <i className="ri-video-line"></i>
              <span className="title">Reels</span>
            </NavLink>
          </li>
          <li>
            <button
              type="button"
              onClick={() => setActiveMessage(!isActiveMessage)}
            >
              <i className="ri-chat-1-line"></i>
              <span className="title">Messages</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => setActiveNotify(!isActiveNotify)}
            >
              <i className="ri-notification-3-line"></i>
              <span className="title">Notification</span>
            </button>
          </li>
          <li>
            <Link to="/create">
              <i className="ri-add-circle-line"></i>
              <span className="title">create</span>
            </Link>
          </li>
          <li>
            <Link to={"/profile"}>
              <i className="ri-user-3-line"></i>
              <span className="title">profile</span>
            </Link>
          </li>
          <li>
            <Link to={"/user-profile"}>
              <i className="ri-user-3-line"></i>
              <span className="title">User profile</span>
            </Link>
          </li>
          <li>
            <Link to={"/business-account"}>
              <i className="ri-user-3-line"></i>
              <span className="title">Business profile</span>
            </Link>
          </li>

          <li className="dropdown moreAction">
            <button
              className=""
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={()=>setIsCross(!isCross)}
              >
                {isCross ? <i className="ri-close-fill"></i> : <i className="ri-menu-line"></i>}
              <span className="title">More</span>
            </button>
            <ul className="dropdown-menu dropdown-menu-dark">
              <li>
                <div className="dropdown-item">
                  <div className="modeChange form-check form-switch">
                    <label className="form-check-label" htmlFor="modeID">
                      <i className="ri-moon-line me-2"></i> 
                  <span className="text">Mode</span>
                      
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="modeID"
                      onClick={handleTheme}
                    />
                  </div>
                </div>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link className="dropdown-item" to="/setting">
                  <i className="ri-settings-5-line"></i> 
                  <span className="text">Setting</span>
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/login">
                  <i className="ri-logout-box-line"></i> 
                  <span className="text">Logout</span>

                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <CreatePostModal show={show} handleClose={handleClose} />
    </>
  );
};

export default Menus;
