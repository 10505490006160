import React from "react";
import select1 from "../../assets/img/create/select1.png";
import select2 from "../../assets/img/create/select2.png";
import select3 from "../../assets/img/create/select3.png";
import select4 from "../../assets/img/create/select4.png";
import select5 from "../../assets/img/create/select5.png";
import { Link } from "react-router-dom";

const Create = () => {
  return (
    <>
      <div className="createPostSec p-100">
        <div className="sectionTitle text-center">
          <h2>Select photo & video</h2>
        </div>
        <div className="createPostCard">
          <div className="getImageURL">
            <input type="file" />
            <div className="getIcon">
              <i className="ri-image-fill"></i>
            </div>
          </div>
          <div className="multiSelect">
            <div className="multiSelectCard">
              <img src={select1} alt="Select" className="img-fluid" />
              <div className="removeIcon">
                <i className="ri-close-line"></i>
              </div>
              <div className="playIcon">
                <i className="ri-play-circle-line"></i>
              </div>
            </div>
            <div className="multiSelectCard">
              <img src={select2} alt="Select" className="img-fluid" />
              <div className="removeIcon">
                <i className="ri-close-line"></i>
              </div>
              <div className="playIcon">
                <i className="ri-play-circle-line"></i>
              </div>
            </div>
            <div className="multiSelectCard">
              <img src={select3} alt="Select" className="img-fluid" />
              <div className="removeIcon">
                <i className="ri-close-line"></i>
              </div>
              <div className="playIcon">
                <i className="ri-play-circle-line"></i>
              </div>
            </div>
            <div className="multiSelectCard">
              <img src={select4} alt="Select" className="img-fluid" />
              <div className="removeIcon">
                <i className="ri-close-line"></i>
              </div>
              <div className="playIcon">
                <i className="ri-play-circle-line"></i>
              </div>
            </div>
            <div className="multiSelectCard">
              <img src={select5} alt="Select" className="img-fluid" />
              <div className="removeIcon">
                <i className="ri-close-line"></i>
              </div>
              <div className="playIcon">
                <i className="ri-play-circle-line"></i>
              </div>
            </div>
          </div>
          <div className="sendButton">
            <Link to="/upload-post" >
              <i className="ri-arrow-right-line"></i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create;
