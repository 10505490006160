import React from "react";
import uss from "../../../assets/img/uss.png";
import ShareVideoList from "./share-video-list/ShareVideoList";

const ShareVideo = ({ shareVideo, handleParent }) => {
    const handleChild = (e) => {
        e.stopPropagation();
    }
  return (
    <>
      <div className={shareVideo ? "reelsMore active" : "reelsMore"}>
        <div className="relBox" onClick={()=> handleParent("shareVideo")}>
          <div className="shareVideoInfo" onClick={handleChild}>
            <div className="writeMsg">
              <img src={uss} alt="User" className="img-fluid" />
              <input
                type="text"
                placeholder="Write a message"
                className="form-control"
              />
            </div>
            <hr />
            <div className="shareVideoBody">
              <div className="searchShare">
                <input
                  type="text"
                  placeholder="Write a message"
                  className="form-control"
                />
                <div className="searchIcon">
                  <i className="ri-search-line"></i>
                </div>
              </div>
              <ShareVideoList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareVideo;
