import React from "react";
import Stepper from "awesome-react-stepper";
import acc from "../../assets/img/account/acc.png";
import Frame from "../../assets/img/account/Frame.png";
import self from "../../assets/img/account/self.png";
import Group from "../../assets/img/account/group1.png";

const BusinessAccount = () => {
  return (
    <>
      <div className="businessaccountPage p-100">
        <div className="sectionTitle">
          <h2>Business Account</h2>
        </div>

        <Stepper>
          <div className="stepsCard">
            <div className="circleImg">
              <img src={acc} alt="Account" className="img-fluid" />
            </div>
            <div className="aboutText">
              <h3>Hi Faiza Kublai! Welcome to Business Account</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </p>
              <button type="button" className="btn btn-warning">
                {" "}
                Next{" "}
              </button>
            </div>
          </div>
          <div className="stepsCard">
            <div className="circleImg">
              <img
                src={Frame}
                alt="Account"
                className="img-fluid"
                style={{ filter: "grayscale(1)" }}
              />
            </div>
            <div className="aboutText">
              <h3>Learn About your audience</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum
              </p>
              <button type="button" className="btn btn-warning">
                {" "}
                Next{" "}
              </button>
            </div>
          </div>
          <div className="stepsCard">
            <div className="circleImg">
              <img
                src={self}
                alt="Account"
                className="img-fluid"
                style={{ filter: "grayscale(1)" }}
              />
            </div>
            <div className="aboutText">
              <h3>Reach More People</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum
              </p>
              <button type="button" className="btn btn-warning">
                {" "}
                Next{" "}
              </button>
            </div>
          </div>

          <div className="stepsCard">
            <div className="circleImg">
              <img
                src={Group}
                alt="Account"
                className="img-fluid"
                style={{ filter: "grayscale(1)" }}
              />
            </div>
            <div className="aboutText">
              <h3>Access conversion tools</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum
              </p>
              <button type="button" className="btn btn-warning">
                {" "}
                Next{" "}
              </button>
            </div>
          </div>

          <div className="stepsCard text-start">
            <div className="categoryAcc">
              <h5>Choose a category</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and type</p>
            </div>
            <div className="selectCategoryAcc">
              <div className="selectCategItem form-check">
                <label className="form-check-label" htmlFor="artId">
                  Art & Craft
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="art"
                  id="artId"
                />
              </div>
              <div className="selectCategItem form-check">
                <label className="form-check-label" htmlFor="babyId">
                  Baby
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="baby"
                  id="babyId"
                />
              </div>
              <div className="selectCategItem form-check">
                <label className="form-check-label" htmlFor="beautyId">
                  Beauty
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="beauty"
                  id="beautyId"
                />
              </div>
              <div className="selectCategItem form-check">
                <label className="form-check-label" htmlFor="educationId">
                Education & Training
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="education"
                  id="educationId"
                />
              </div>
              <div className="selectCategItem form-check">
                <label className="form-check-label" htmlFor="electronicsId">
                Electronics
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="electronics"
                  id="electronicsId"
                />
              </div>
            </div>
          </div>
        </Stepper>
      </div>
    </>
  );
};

export default BusinessAccount;
