import React from 'react'
import EditProfile from '../../components/profile/edit-profile/EditProfile'

const EditProfilePage = () => {
  return (
    <>
        <EditProfile />
    </>


  )
}

export default EditProfilePage