import React from "react";

const SearchCard = ({ data, handleRemove }) => {
  const { id, user_name, user_img, user_text } = data;
  return (
    <>
      <div className="searchItem">
        <div className="searchUserDetail">
          <div className="userFigure">
            <img src={user_img} alt={user_name} className="img-fluid" />
          </div>
          <div className="userText">
            <h6>{user_name}</h6>
            <p>
              {user_text} .<span className="following"> Following</span>
            </p>
          </div>
        </div>
        <div className="cancelUser">
          <p>Follow</p>
        </div>
      </div>
    </>
  );
};

export default SearchCard;
