import React from 'react'
import Reels from '../../components/reels/Reels'

const ReelsPage = () => {
  return (
    <>
        <Reels />
    </>
  )
}

export default ReelsPage