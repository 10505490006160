import React from 'react'
import { Link } from 'react-router-dom';
import post1 from "../../../../assets/img/profile/post/post1.png";
import post2 from "../../../../assets/img/profile/post/post2.png";
import post3 from "../../../../assets/img/profile/post/post3.png";
import post4 from "../../../../assets/img/profile/post/post4.png";
import post5 from "../../../../assets/img/profile/post/post5.png";
import post6 from "../../../../assets/img/profile/post/post6.png";
import post7 from "../../../../assets/img/profile/post/post7.png";
import post8 from "../../../../assets/img/profile/post/post8.png";

const ProfilePost = () => {
  return (
    <>
        <div className="profilePostCard">
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post1} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post2} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post3} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post4} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post5} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post6} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post7} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post8} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post1} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post4} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post5} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
            <div className="profilePostItem">
                <Link to="#">
                    <img src={post4} alt="Profile Post" className='img-fluid' />
                </Link>
            </div>
        </div>
    </>
  )
}

export default ProfilePost