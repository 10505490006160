import React from 'react'
import Follow from '../../components/follow/Follow'

const FollowPage = () => {
  return (
    <>
        <Follow />
    </>
  )
}

export default FollowPage