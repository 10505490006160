import React from 'react'
import Comments from '../../components/comments/Comments'

const CommentsPage = () => {
  return (
    <>
        <Comments />
    </>
  )
}

export default CommentsPage