import React from 'react'
import MessageUsers from './message-users/MessageUsers'

const MessageLists = () => {
  return (
    <>
        <div className="searchBarSec message">
            <div className="title">
                <h4>Message</h4>
                <div className="headerCircle"><i className="ri-search-line"></i></div>
            </div>
            <MessageUsers />
        </div>
        
    </>
  )
}

export default MessageLists