import React from "react";
import FollowItem from "./follow-item/FollowItem";

import poster from "../../assets/img/follow/user.png";
const followData = [
  {
    id: 1,
    name: "Anna Bella",
    useImg: poster,
    userID: "@anna_balla",
  },
  {
    id: 2,
    name: "Anna Bella",
    useImg: poster,
    userID: "@anna_balla",
  },
  {
    id: 3,
    name: "Anna Bella",
    useImg: poster,
    userID: "@anna_balla",
  },
  {
    id: 4,
    name: "Anna Bella",
    useImg: poster,
    userID: "@anna_balla",
  },
  {
    id: 5,
    name: "Anna Bella",
    useImg: poster,
    userID: "@anna_balla",
  },
  {
    id: 6,
    name: "Anna Bella",
    useImg: poster,
    userID: "@anna_balla",
  },
  {
    id: 7,
    name: "Anna Bella",
    useImg: poster,
    userID: "@anna_balla",
  },
  {
    id: 8,
    name: "Anna Bella",
    useImg: poster,
    userID: "@anna_balla",
  },
  {
    id: 9,
    name: "Anna Bella",
    useImg: poster,
    userID: "@anna_balla",
  },
];
const Follow = () => {
  return (
    <>
      <section className="followSec p-100">
          <div className="sectionTitle">
            <h2>top suggestion</h2>
          </div>
          <div className="followCardInfo">
            {followData &&
              followData.map((item) => {
                return <FollowItem key={item.id} data={item} />;
              })}
          </div>
      </section>
    </>
  );
};

export default Follow;
