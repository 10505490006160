import React from "react";
import user from "../../assets/img/uss.png";

const Comments = () => {
  return (
    <>
      <div className="commentInfo">
        <div className="sectionTitle">
          <h2>Comments</h2>
        </div>
        <div className="commentInner">
          <div className="commentCard">
            <div className="commentHeader">
              <div className="commentUser">
                <img src={user} className="img-fluid" alt="Anna Bella" />
                <div className="text">
                  <h4>Anna Bella</h4>
                  <p>@Anna bella</p>
                </div>
              </div>
              <div className="more">
                <i className="ri-more-2-fill"></i>
              </div>
            </div>
            <div className="commentBody">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </p>
              <strong>2D</strong>
            </div>
            <div className="commentFooter">
              <ul>
                <li>
                  <i className="ri-heart-3-line"></i>
                  <span className="num">230</span>
                </li>
                <li>
                  <i className="ri-chat-4-line"></i>
                  <span className="num">10</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="commentCard">
            <div className="commentHeader">
              <div className="commentUser">
                <img src={user} className="img-fluid" alt="Anna Bella" />
                <div className="text">
                  <h4>Anna Bella</h4>
                  <p>@Anna bella</p>
                </div>
              </div>
              <div className="more">
                <i className="ri-more-2-fill"></i>
              </div>
            </div>
            <div className="commentBody">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </p>
              <strong>2D</strong>
            </div>
            <div className="commentFooter">
              <ul>
                <li>
                  <i className="ri-heart-3-line"></i>
                  <span className="num">230</span>
                </li>
                <li>
                  <i className="ri-chat-4-line"></i>
                  <span className="num">10</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="commentCard">
            <div className="commentHeader">
              <div className="commentUser">
                <img src={user} className="img-fluid" alt="Anna Bella" />
                <div className="text">
                  <h4>Anna Bella</h4>
                  <p>@Anna bella</p>
                </div>
              </div>
              <div className="more">
                <i className="ri-more-2-fill"></i>
              </div>
            </div>
            <div className="commentBody">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
              </p>
              <strong>2D</strong>
            </div>
            <div className="commentFooter">
              <ul>
                <li>
                  <i className="ri-heart-3-line"></i>
                  <span className="num">230</span>
                </li>
                <li>
                  <i className="ri-chat-4-line"></i>
                  <span className="num">10</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comments;
