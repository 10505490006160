import React from "react";
import img1 from "../../assets/img/posts/1.png";
import img2 from "../../assets/img/posts/2.png";
import img3 from "../../assets/img/posts/3.png";
import img4 from "../../assets/img/posts/4.png";
import img5 from "../../assets/img/posts/5.png";

import user1 from "../../assets/img/posts/user1.png";
import user2 from "../../assets/img/posts/user2.png";
import user3 from "../../assets/img/posts/user3.png";
import user4 from "../../assets/img/posts/user4.png";

import NearbyItem from "./nearby-item/NearbyItem";
const postData = [
  {
    id: 1,
    name: "Lorem Ipsum is simply dumm",
    url: img1,
    wishlist: "36525",
    user: "user.30",
    userImg: user1,

  },
  {
    id: 2,
    name: "Lorem Ipsum is simply dumm",
    url: img2,
    wishlist: "36525",
    userImg: user2,
    user: "user.30"
  },
  {
    id: 3,
    name: "Lorem Ipsum is simply dumm",
    url: img3,
    wishlist: "36525",
    user: "user.30",
    userImg: user3,

  },
  {
    id: 4,
    name: "Lorem Ipsum is simply dumm",
    url: img4,
    wishlist: "36525",
    user: "user.30",
    userImg: user4,

  },
  {
    id: 5,
    name: "Lorem Ipsum is simply dumm",
    url: img5,
    wishlist: "36525",
    user: "user.30",
    userImg: user1,

  },
  {
    id: 6,
    name: "Lorem Ipsum is simply dumm",
    url: img1,
    wishlist: "36525",
    user: "user.30",
    userImg: user2,

  },
  {
    id: 7,
    name: "Lorem Ipsum is simply dumm",
    url: img4,
    wishlist: "36525",
    user: "user.30",
    userImg: user4,

  },
  {
    id: 8,
    name: "Lorem Ipsum is simply dumm",
    url: img5,
    wishlist: "36525",
    user: "user.30",
    userImg: user1,

  }
];
const Nearby = () => {
  return (
    <>
      <section className="PostsSec p-100">
            <div className="sectionTitle">
                <h2>Nearby</h2>
            </div>
          <div className="postCard">
            {postData &&
              postData.map((item) => {
                return <NearbyItem key={item.id} data={item} />;
              })}
          </div>
      </section>
    </>
  );
};

export default Nearby;
