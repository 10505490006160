import { Modal } from "react-bootstrap";
import React from "react";

const BeginModal = ({data}) => {
    const {handleClose, show} = data;
  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="beginModal">
        <Modal.Body>Work In Progress</Modal.Body>
      </Modal>
    </>
  );
};

export default BeginModal;
