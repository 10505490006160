import React from 'react'

const UserProfileData = () => {
  return (
    <>
        <div className="ProfileDataSec userProf">
            <div className="ProfileNumber">
                <h5 className='number'>4</h5>
                <p className='name'>post</p>
            </div>
            <div className="ProfileNumber">
                <h5 className='number'>5620</h5>
                <p className='name'>Followers</p>
            </div>
            <div className="ProfileNumber">
                <h5 className='number'>526</h5>
                <p className='name'>Following</p>
            </div>
        </div>
    </>
  )
}

export default UserProfileData