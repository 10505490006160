import React, { useState } from "react";
import SearchCard from "./search-card/SearchCard";
import elipse1 from "../../../../assets/img/search/elipse1.png";
import elipse2 from "../../../../assets/img/search/elipse2.png";
import elipse3 from "../../../../assets/img/search/elipse3.png";
import elipse4 from "../../../../assets/img/search/elipse4.png";

const searchUserData = [
  {
    id: 1,
    user_name: "User__90",
    user_img: elipse1,
    user_text: "User Coper",
  },
  {
    id: 2,
    user_name: "User__90",
    user_img: elipse2,
    user_text: "User Coper",
  },
  {
    id: 3,
    user_name: "User__90",
    user_img: elipse3,
    user_text: "User Coper",
  },
  {
    id: 4,
    user_name: "User__90",
    user_img: elipse4,
    user_text: "User Coper", 
  },
];

const SearchUsers = () => {
  const [data, setData] = useState(searchUserData);
  const handleRemove = (index) => {
    const filterData = data.filter((item) => {
      return item.id !== index;
    });
    setData(filterData);
  };
  return (
    <>
      <div className="searchUserSec">
       
        {data &&
          data.map((item) => {
            return (
              <SearchCard
                key={item.id}
                data={item}
                handleRemove={handleRemove}
              />
            );
          })}
      </div>
    </>
  );
};

export default SearchUsers;
