import Modal from "react-bootstrap/Modal";
import React from "react";
import imageGallery from "../../../../../assets/img/create/image-gallery.png";

const CreatePostModal = ({ show, handleClose }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="create_post">
        <Modal.Body>
          <div className="createPostBody">
            <div className="header">
              <h3>Create new post</h3>
            </div>
            <img src={imageGallery} alt="" className="img-fluid" />
            <h4>Drag photos and videos here</h4>
            <div className="upload-btn-wrapper">
              <button type="button" className="btn btn-primary">
                Select from computer
              </button>
              <input type="file" name="myfile" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatePostModal;
