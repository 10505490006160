import React from 'react'
import BusinessAccount from '../../components/business-account/BusinessAccount'

const BusinessAccountPage = () => {
  return (
    <>
        <BusinessAccount />
    </>
  )
}

export default BusinessAccountPage