import React from 'react'
import UploadPost from '../../components/create/upload-post/UploadPost'

const UploadPostPage = () => {
  return (
    <>
        <UploadPost />
    </>
  )
}

export default UploadPostPage