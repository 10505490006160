import React from 'react';
import profileImg from "../../../assets/img/profile/profile.png"

const ProfileName = () => {
  return (
    <>
        <div className="profileNameCard">
            <div className="prof">
                <img src={profileImg} alt="Profile" className='img-fluid' />
            </div>
            <div className="text">
                <h6>Fiza Kublia</h6>
                <p>@fizakublia254</p>
            </div>
        </div>
    </>
  )
}

export default ProfileName