import React from 'react'
import Nearby from '../../components/nearby/Nearby'

const NearbyPage = () => {
  return (
    <>
        <Nearby />
    </>
  )
}

export default NearbyPage