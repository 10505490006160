import React from 'react'

const SearchArea = () => {
  return (
    <>
        <div className="searchArea">
            <input type="text" placeholder='Search' className='form-control' />
            <i className="ri-close-circle-fill"></i>
        </div>
    </>


  )
}

export default SearchArea