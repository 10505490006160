import React from "react";
import { Link } from "react-router-dom";

const EditProfile = () => {
  return (
    <>
      <div className="editProfileSec p-100">
        <div className="sectionTitle">
          <h2>About You</h2>
        </div>
        <div className="userList">
          <ul>
            <li>
              <strong className="title">Name</strong>
              <button type="button" className="name">
                Fiza kubila <i className="ri-arrow-right-s-line"></i>{" "}
              </button>
            </li>
            <li>
              <strong className="title">Username</strong>
              <button type="button" className="name">
                @Fiza_kubila <i className="ri-arrow-right-s-line"></i>{" "}
              </button>
            </li>
            <li>
              <strong className="title"></strong>
              <button type="button" className="name">
                Flowtime.com/dfs <i className="ri-file-copy-fill"></i>
              </button>
            </li>
            <li>
              <strong className="title">Bio</strong>
              <button type="button" className="name">
                Lorem Ipsum is simply dumm...{" "}
                <i className="ri-arrow-right-s-line"></i>{" "}
              </button>
            </li>
            <li>
              <strong className="title">Business phone number</strong>
              <button type="button" className="name">
                Add your number <i className="ri-arrow-right-s-line"></i>{" "}
              </button>
            </li>
            <li>
              <strong className="title">Business Address</strong>
              <button type="button" className="name">
                Add your address <i className="ri-arrow-right-s-line"></i>{" "}
              </button>
            </li>
            <li>
              <strong className="title">Email </strong>
              <button type="button" className="name">
                Add your email <i className="ri-arrow-right-s-line"></i>{" "}
              </button>
            </li>
            <li>
              <strong className="title">Category</strong>
              <button type="button" className="name">
                Food, pets.. <i className="ri-arrow-right-s-line"></i>{" "}
              </button>
            </li>
            <li>
              <strong className="title">Private </strong>
              <button type="button" className="name">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckChecked"
                    defaultChecked
                  />
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
