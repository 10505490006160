import React from "react";
import chat1 from "../../assets/img/chat/chat1.png";
import { BiSolidMicrophone } from "react-icons/bi";
const Chat = () => {
  return (
    <>
      <div className="ChatSecCard">
        <div className="chatHeader">
          <div className="userStatus">
            <div className="backArrow">
              <i className="ri-arrow-left-s-line"></i>
            </div>
            <div className="userInf">
              <div className="profile">
                <img src={chat1} alt={"Anna Bella"} className="img-fluid" />
              </div>
              <div className="profileText">
                <h4>Anna Bella</h4>
                <p>Online</p>
              </div>
            </div>
          </div>
          <div className="userSetting">
            <i className="ri-more-2-fill"></i>
          </div>
        </div>
        <div className="chatBody">
          <div className="chatPart">
            <div className="sendMessage">
              <p>I Will Sir We accept your request for your Services</p>
              <span className="time">12:15 PM</span>
            </div>
            <div className="receiveMessage">
              <p>Hello! yes i get invoice from your salon, i will come near</p>
              <span className="time">12:15 PM</span>
            </div>
            <div className="sendMessage">
              <p>Thanks sir you have a Good day</p>
              <span className="time">12:15 PM</span>
            </div>
          </div>
        </div>
        <div className="chatFooter">
          <div className="chatInput">
            <div className="addEmoji">
              <i className="ri-emoji-sticker-line"></i>
            </div>
            <input
              type="text"
              placeholder="Type a message"
              className="form-control"
            />
            <div className="add">
              <div className="addMedia">
                <i className="ri-add-fill"></i>
              </div>
              <div className="addAudio">
                <BiSolidMicrophone />
              </div>
            </div>
          </div>
          <div className="chatSendButton">
            <button className="btn btn-primary" type="button">
              <i className="ri-send-plane-2-line"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
