import React from 'react'
import Account from '../../components/account/Account'

const AccountPage = () => {
  return (
    <>
        <Account />
    </>
  )
}

export default AccountPage