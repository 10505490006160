import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from '../home/sidebar/Sidebar'

const MainComp = () => {
  return (
    <>
        <section className="themeStructure">
        <Sidebar />
            <div className="mainBody">
            <Outlet></Outlet>
            </div>
      </section>
    </>
  )
}

export default MainComp