import React from 'react'
import Chat from '../../components/chat/Chat'

const ChatPage = () => {
  return (
    <>
        <Chat />
    </>
  )
}

export default ChatPage