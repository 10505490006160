import React from 'react';
import user from "../../../assets/img/follow/user.png";

const SwitchAccount = () => {
  return (
    <>
        <div className="switchAccount">
            <div className="userInfo">
                <div className="userImg">
                    <img src={user} alt="User" className='img-fluid' />
                </div>
                <div className="userText">
                    <h5>John Doe</h5>
                    <p>John Front-End</p>
                </div>
            </div>
            <div className="switchText">
                <p>Switch</p>
            </div>
        </div>
    </>
  )
}

export default SwitchAccount