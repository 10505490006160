import React from "react";
import StatusShow from "./status-show/StatusShow";
import Explore from "./explore/Explore";
import SwitchAccount from "./switch-account/SwitchAccount";
import SearchUsers from "./switch-account/search-users/SearchUsers";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
      <div className="homeBody">
        <div className="postArea">
          <StatusShow />
          <Explore />
        </div>
        <div className="switchArea">
          <div>
          <SwitchAccount />
          <div className="suggest">
            <p>Suggested for you</p>
            <Link to={"/"}>See All</Link>
          </div>
          <SearchUsers />
          <div className="webLinks">
            <ul>
              <li>
                <Link to="#">About</Link>
              </li>
              <li>
                <Link to="#">Help</Link>
              </li>
              <li>
                <Link to="#">Press</Link>
              </li>
              <li>
                <Link to="#">API</Link>
              </li>
              <li>
                <Link to="#">Jobs</Link>
              </li>
              <li>
                <Link to="#">Privacy</Link>
              </li>
              <li>
                <Link to="#">Terms</Link>
              </li>
              <li>
                <Link to="#">Locations</Link>
              </li>
              <li>
                <Link to="#">Language</Link>
              </li>
              <li>
                <Link to="#">Meta Verified</Link>
              </li>
            </ul>
          </div>
          <p className="copyRight">
            &#169; {new Date().getFullYear()} FLOWTIME{" "}
          </p>
        </div></div>
      </div>
    </>
  );
};

export default Home;
