

import React from "react";
import { Link } from "react-router-dom";

const ShareVideoItem = ({ data }) => {
  const { id, user_name, user_img, user_text } = data;
  return (
    <>
      <div className="shareVideoItem">
          <div className="shareVideoItemDetail">
            <div className="userFigure">
              <img src={user_img} alt={user_name} className="img-fluid" />
            </div>
            <div className="userText">
              <h6>{user_name}</h6>
              <p>{user_text}</p>
            </div>
          </div>
          <div className="sent">
            <button type="button" className="btn btn-warning">Sent</button>
          </div>
      </div>
    </>
  );
};

export default ShareVideoItem;
