import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/css/custom.css";
import "./assets/css/responsive.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import LoginPage from "./pages/login";
import RegisterPage from "./pages/register";
import NearbyPage from "./pages/nearby";
import MainPage from "./pages/main-page";
import FollowPage from "./pages/follow";
import ReelsPage from "./pages/reels";
import MessagePage from "./pages/message";
import ProfilePage from "./pages/profile";
import EditProfilePage from "./pages/edit-profile";
import SettingPage from "./pages/setting";
import AccountPage from "./pages/account";
import BusinessAccountPage from "./pages/bussiness-account";
import BusinessProfilePage from "./pages/business-profile";
import UserProfilePage from "./pages/user-profile";
import CreatePage from "./pages/create";
import UploadPostPage from "./pages/upload-post";
import ChatPage from "./pages/chat";
import CommentsPage from "./pages/comments";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainPage />}>
          <Route path="" element={<HomePage />}></Route>
          <Route path="/nearby" element={<NearbyPage />}></Route>
          <Route path="/follow" element={<FollowPage />}></Route>
          <Route path="/reels" element={<ReelsPage />}></Route>
          <Route path="/message" element={<MessagePage />}></Route>
          <Route path="/profile" element={<ProfilePage />}></Route>
          <Route path="/edit-profile" element={<EditProfilePage />}></Route>
          <Route path="/setting" element={<SettingPage />}></Route>
          <Route path="/account" element={<AccountPage />}></Route>
          <Route path="/business-account" element={<BusinessAccountPage />}></Route>
          <Route path="/user-profile" element={<UserProfilePage />}></Route>
          <Route path="/create" element={<CreatePage />}></Route>
          <Route path="/upload-post" element={<UploadPostPage />}></Route>
          <Route path="/chat" element={<ChatPage />}></Route>
          <Route path="/comments" element={<CommentsPage />}></Route>
        </Route>
        
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/register" element={<RegisterPage />}></Route>
      </Routes>
    </>
  );
}

export default App;
