import React from 'react'
import Register from '../../components/register/Register'

const RegisterPage = () => {
  return (
    <>
        <Register />
    </>
  )
}

export default RegisterPage