import React from "react";
import ProfilePost from "./profile-post/ProfilePost";

const SavePostVideos = () => {
  return (
    <>
      <div className="profileSaveTab">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="post-tab"
                data-bs-toggle="tab"
                data-bs-target="#post-tab-pane"
                type="button"
                role="tab"
                aria-controls="post-tab-pane"
                aria-selected="true"
              >
                <i className="ri-table-2"></i>
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="saved-tab"
                data-bs-toggle="tab"
                data-bs-target="#saved-tab-pane"
                type="button"
                role="tab"
                aria-controls="saved-tab-pane"
                aria-selected="false"
              >
                <i className="ri-bookmark-line"></i>
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="post-tab-pane"
              role="tabpanel"
              aria-labelledby="post-tab"
              tabIndex={0}
            >
              <ProfilePost />
            </div>
            <div
              className="tab-pane fade"
              id="saved-tab-pane"
              role="tabpanel"
              aria-labelledby="saved-tab"
              tabIndex={0}
            >
              Saved
            </div>
          </div>
        </div>
    </>
  );
};

export default SavePostVideos;
