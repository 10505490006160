import React from "react";
import logo from "../../assets/img/logo/logo.png";
import google from "../../assets/img/login/google.png";
import googleImg from "../../assets/img/google.png";
import microsoft from "../../assets/img/microsoft.png";
import login_bg from "../../assets/img/login/login_bg.png";
import facebook from "../../assets/img/login/facebook.png";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <>
      <section className="loginPage">
        <div className="loginBox">
          <div className="loginInfo">
            <div className="loginFigure">
              
            <img src={login_bg} alt="Flow Time" className="img-fluid" />

            </div>
            <div className="loginForm">
              <div className="brand">
                <img src={logo} alt="Flow Time" className="img-fluid" />
              </div>
              <div className="loginBody">
                <div className="welcome mt-2">Welcome back!</div>
                <form className="loginFormField">
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="Enter your email"
                      className="form-control"
                      autoComplete="off"
                      name="email"
                    />
                  </div>
                  <div>
                    <input
                      type="password"
                      placeholder="Enter your password"
                      className="form-control"
                      autoComplete="off"
                      name="password"
                    />
                  </div>
                  <div className="forgotPass">
                    <Link to="/reset">Forgot password?</Link>
                  </div>

                  {/* {isError && <p className="text-danger mb-1">login Fail ! </p>}
                {isSuccess && (
                  <p className="text-green mb-1">login Successfully !</p>
                )} */}
                  <button className="btn btn-primary enterLogin" type="button">
                    Login
                    {/* {isLoading && (
                    <Spinner style={{ marginLeft: "7px" }} animation="border" />
                  )} */}
                  </button>
                </form>
              </div>
              <div className="orOption">
                <span>or</span>
              </div>

              <div className="loginFooter">
                <div className="loginWithSocial">
                  <Link to="/" className="social">
                    <img src={google} alt="Google" className="img-fluid" />
                  </Link>
                  <Link to="/" className="social">
                    <img src={facebook} alt="Google" className="img-fluid" />
                  </Link>
                </div>
                <div className="registerNow">
                  <p>
                    Don't have an account?{" "}
                    <Link to={"/register"}>Register now</Link>
                  </p>
                </div>
              </div>
              <div className="getApp">
                <h6>Get the app.</h6>
                <div className="appFigure">
                  <Link to={"/"}><img src={googleImg} className="img-fluid" /> </Link>
                  <Link to={"/"}><img src={microsoft} className="img-fluid" /> </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
