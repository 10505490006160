import React from "react";
import { Link } from "react-router-dom";

const MessageCard = ({ data, handleRemove }) => {
  const { id, user_name, user_img, user_text } = data;
  return (
    <>
      <div className="searchItem messageItem">
        <Link to={"/chat"}>
          <div className="searchUserDetail">
            <div className="userFigure">
              <img src={user_img} alt={user_name} className="img-fluid" />
            </div>
            <div className="userText">
              <h6>{user_name}</h6>
              <p>{user_text}</p>
            </div>
          </div>
          <div className="cancelUser">
            <p className="time">12:22 PM</p>
            <span className="length">4</span>
          </div>
        </Link>
      </div>
    </>
  );
};

export default MessageCard;
