import React, { useState } from "react";
import notification1 from "../../../../../assets/img/notification/notification1.png";
import notification2 from "../../../../../assets/img/notification/notification2.png";
import notification3 from "../../../../../assets/img/notification/notification3.png";
import notification4 from "../../../../../assets/img/notification/notification4.png";
import notification5 from "../../../../../assets/img/notification/notification5.png";
import NotificationCard from "./notification-card/NotificationCard"
const searchUserData = [
  {
    id: 1,
    user_name: "Wade Warren",
    user_img: notification1,
    user_text: "User Coper",
  },
  {
    id: 2,
    user_name: "User__90",
    user_img: notification2,
    user_text: "User Coper",
  },
  {
    id: 3,
    user_name: "Wade Warren",
    user_img: notification3,
    user_text: "User Coper",
  },
  {
    id: 4,
    user_name: "User__90",
    user_img: notification4,
    user_text: "User Coper",
  },
  {
    id: 5,
    user_name: "Wade Warren",
    user_img: notification1,
    user_text: "User Coper",
  },
  {
    id: 6,
    user_name: "User__90",
    user_img: notification2,
    user_text: "User Coper",
  },
  {
    id: 7,
    user_name: "Wade Warren",
    user_img: notification3,
    user_text: "User Coper",
  },
  {
    id: 8,
    user_name: "User__90",
    user_img: notification4,
    user_text: "User Coper",
  },
];

const NotificationCards = () => {
  const [data, setData] = useState(searchUserData);
  const handleRemove = (index) => {
    const filterData = data.filter((item) => {
      return item.id !== index;
    });
    setData(filterData);
  };
  return (
    <>
      <div className="searchUserSec notify">
        {data &&
          data.map((item) => {
            return (
              <NotificationCard
                key={item.id}
                data={item}
                handleRemove={handleRemove}
              />
            );
          })}
      </div>
    </>
  );
};

export default NotificationCards;
