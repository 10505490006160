import React from "react";
import NotificationCards from "./notification-cards/NotificationCards";

const NotificationModal = ({ setActiveNotify }) => {
  return (
    <>
      <div className="searchBarSec">
        <div className="title">
          <h4>
            {" "}
            <i
              className="ri-arrow-left-s-line"
              onClick={() => setActiveNotify(false)}
            ></i>{" "}
            Notification
          </h4>
        </div>
        <NotificationCards />
      </div>
    </>
  );
};

export default NotificationModal;
