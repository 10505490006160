import React, { useState } from "react";
import reels from "../../assets/img/reels/reels1.png";
import elipse2 from "../../assets/img/reels/elipse2.png";
import MoreComp from "./more-comp/MoreComp";
import ShareVideo from "./share-video/ShareVideo";
import { Link } from "react-router-dom";

const Reels = () => {
  const [moreReels, setMoreReels] = useState(false);
  const [shareVideo, setShareVideo] = useState(false);
  const handleReel = () => {
    setMoreReels(!moreReels);
  };
  const handleShareVideo = () => {
    setShareVideo(!shareVideo);
  };
  const handleParent = (name) => {
    if (name == "shareVideo") {
    setShareVideo(false)
      
    }
    else{
      setMoreReels(false)
    }
  }
  return (
    <>
      <section className="reelsSec p-100">
        <div className="sectionTitle">
          <h2>top Reels</h2>
        </div>

        <div className="shortVideoCard">
          <div className="reelsCard">
            <img src={reels} alt="Reels" className="img-fluid" />
            <div className="reelsBottomContent">
              <div className="followUser">
                <img src={elipse2} alt="Reels" className="img-fluid" />
                <h5>User__13</h5>
                <button type="button">Follow</button>
              </div>
              <div className="followPara">
                <p>The golden hours.... life is beautiful!</p>
                <div className="music">
                  <i className="ri-music-2-line"></i>
                  <p>Tickettobollywood(boy)</p>
                </div>
              </div>
            </div>
            <div className="rightShare">
              <div className="share">
                <ul>
                  <li>
                    <i className="ri-heart-3-line"></i>
                    <span className="num">230</span>
                  </li>
                  <li>
                    <Link to={"/comments"}>
                      <i className="ri-chat-3-line"></i>
                      <span className="num">10</span>
                    </Link>
                  </li>
                  <li onClick={handleShareVideo}>
                    <i className="ri-share-forward-line"></i>
                  </li>
                  <li onClick={handleReel}>
                    <i className="ri-more-fill"></i>
                  </li>
                </ul>
              </div>
            </div>

            <div className="muteSound">
              <div className="mute">
                <i className="ri-volume-mute-line"></i>
              </div>
            </div>
            {moreReels && <MoreComp moreReels={moreReels} handleParent={handleParent} />}
            {shareVideo && <ShareVideo shareVideo={shareVideo} handleParent={handleParent} />}
          </div>
        </div>
      </section>
    </>
  );
};

export default Reels;
