import React from 'react'
import { Link } from 'react-router-dom'

const Account = () => {
  return (
    <>
        <div className="accountSec">
            <div className="sectionTitle">
                <h2>Account</h2>
            </div>
            <div className="switchText">
                <Link to={"/business-account"}>Switch to business account</Link>
            </div>
        </div>
    </>


  )
}

export default Account