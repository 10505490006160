import React from 'react'
import MainComp from '../../components/main-comp/MainComp'

const MainPage = () => {
  return (
    <>
        <MainComp />
    </>
  )
}

export default MainPage