import React from "react";

const NearbyItem = ({ data }) => {
  const { name, wishlist, user, url, userImg } = data;
  return (
    <>
      <div className="postItem">
        <div className="postItemImg">
          <img src={url} alt={name} className="img-fluid" />
        </div>
        <div className="postItemContent">
          <h5>{name}</h5>
          <div className="bottomText">
            <div className="userText">
              <img src={userImg} alt={name} />
              <p className="user">{user}</p>
            </div>
            <p className="wishlist"><i className="ri-heart-3-line"></i> {wishlist}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NearbyItem;
