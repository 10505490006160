import React from 'react'
import Create from '../../components/create/Create'

const CreatePage = () => {
  return (
    <>
        <Create />
    </>
  )
}

export default CreatePage