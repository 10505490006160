import React from "react";

const MoreComp = ({ moreReels, handleParent }) => {
  const handleChild = (e) => {
    e.stopPropagation();
}
  return (
    <>
      <div className={moreReels ? "reelsMore active" : "reelsMore"}>
        <div className="relBox" onClick={handleParent}>
          <ul onClick={handleChild}>
            <li>
              <i className="ri-information-line"></i>
              <span className="text">Report...</span>
            </li>
            <li>
              <i className="ri-file-copy-line"></i>
              <span className="text">Copy Link</span>
            </li>
            <li>
              <i className="ri-share-forward-line"></i>
              <span className="text">Share to...</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MoreComp;
