import React from 'react';
import profileImg from "../../../assets/img/profile/men.png"

const UserProfileName = () => {
  return (
    <>
        <div className="profileNameCard">
            <div className="prof">
                <img src={profileImg} alt="Profile" className='img-fluid' />
            </div>
            <div className="text">
                <h6>Jenny Wilsom</h6>
                <p>@Jenny Wilsom</p>
            </div>
        </div>
    </>
  )
}

export default UserProfileName