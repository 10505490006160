import React, { useState } from 'react'
import ShareVideoItem from './share-video-item/ShareVideoItem'

import message1 from "../../../../assets/img/message/message1.png";
import message2 from "../../../../assets/img/message/message2.png";
import message3 from "../../../../assets/img/message/message3.png";
import message4 from "../../../../assets/img/message/message4.png";
import message5 from "../../../../assets/img/message/message5.png";

const searchUserData = [
  {
    id: 1,
    user_name: "Anna Bella",
    user_img: message1,
    user_text: "Great! thank you so much",
  },
  {
    id: 2,
    user_name: "Anna Bella",
    user_img: message2,
    user_text: "Great! thank you so much",
  },
  {
    id: 3,
    user_name: "Anna Bella",
    user_img: message3,
    user_text: "Great! thank you so much",
  },
  
];

const ShareVideoList = () => {
    const [data, setData] = useState(searchUserData)
  return (
    <>  
      {data &&
          data.map((item) => {
            return (
              <ShareVideoItem
                key={item.id}
                data={item}
              />
            );
          })}
      
    </>
  )
}

export default ShareVideoList