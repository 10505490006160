import React from "react";
import user from "../../../assets/img/follow/user.png";

import elipse1 from "../../../assets/img/follow/elipse1.png";
import elipse2 from "../../../assets/img/follow/elipse2.png";
import elipse3 from "../../../assets/img/follow/elipse3.png";
import elipse4 from "../../../assets/img/follow/elipse4.png";
import { Link } from "react-router-dom";

const StatusShow = () => {
  return (
    <>
      <div className="statusShowSec">
        <div className="statusShowCard">
          <Link to={"/"}>
            <div className="img">
              <img src={elipse3} alt="User" className="img-fluid" />
            </div>
            <p>John Doe</p>
          </Link>
        </div>
        <div className="statusShowCard">
          <Link to={"/"}>
            <div className="img">
              <img src={elipse1} alt="User" className="img-fluid" />
            </div>
            <p>Steve Smith</p>
          </Link>
        </div>
        <div className="statusShowCard">
          <Link to={"/"}>
            <div className="img">
              <img src={user} alt="User" className="img-fluid" />
            </div>
            <p>John Doe</p>
          </Link>
        </div>
        <div className="statusShowCard">
          <Link to={"/"}>
            <div className="img">
              <img src={elipse4} alt="User" className="img-fluid" />
            </div>
            <p>Steve Smith</p>
          </Link>
        </div>
        <div className="statusShowCard">
          <Link to={"/"}>
            <div className="img">
              <img src={user} alt="User" className="img-fluid" />
            </div>
            <p>John Doe</p>
          </Link>
        </div>
        <div className="statusShowCard">
          <Link to={"/"}>
            <div className="img">
              <img src={elipse1} alt="User" className="img-fluid" />
            </div>
            <p>Steve Smith</p>
          </Link>
        </div>
        <div className="statusShowCard">
          <Link to={"/"}>
            <div className="img">
              <img src={elipse4} alt="User" className="img-fluid" />
            </div>
            <p>Steve Smith</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default StatusShow;
