import React from "react";
import { Link } from "react-router-dom";
import UserProfileName from "./user-profile-name/UserProfileName";
import UserProfileData from "./user-profile-data/UserProfileData";
import SavePostVideos from "./user-save-post-video/SavePostVideos";

const UserProfile = () => {
  return (
    <>
      <div className="profileInfo p-100">
        <UserProfileName />
        <UserProfileData />
        <div className="desc">
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus
            debitis, animi nobis quaerat facere dicta,
          </p>
          <p>Bio Lorem Ipsum is simply dumm...</p>
        </div>
        <div className="copyText">
          <p>Flowtime.com/dfs</p>
          <i className="ri-file-copy-fill"></i>
        </div>
        <div className="innerPageBox userProf">
          <div className="pageBtn">
            <Link to={"/edit-profile"}><i className="ri-user-add-fill"></i> Follow</Link>
            <Link to={"#"}><i className="ri-message-2-line"></i> Message</Link>
          </div>
         
        </div>
        <SavePostVideos />
      </div>
    </>
  );
};

export default UserProfile;
