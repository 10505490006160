import React, { useState } from "react";
import logo from "../../../assets/img/logo/logo_bg.png";
import { Link } from "react-router-dom";
import Menus from "./menus/Menus";
import SearchBar from "./search-bar/SearchBar";
import MessageLists from "./message-lists/MessageLists";
import NotificationModal from "./notification-modal/NotificationModal";

const Sidebar = () => {
  const [isActiveSearch, setActiveSearch] = useState(false);
  const [isActiveMessage, setActiveMessage] = useState(false);
  const [isActiveNotify, setActiveNotify] = useState(false);

  return (
    <>
      <div className="sidebar">
        <div className="mainLogo">
          <Link to={"/"}>
            <img src={logo} alt="FLOW TIME" className="img-fluid" />
            <p>FlowTime</p>
          </Link>
        </div>
        <Menus
          data={{
            setActiveSearch,
            isActiveSearch,
            isActiveMessage,
            setActiveMessage,
            isActiveNotify, setActiveNotify
          }}
        />
        {isActiveSearch && <SearchBar  setActiveSearch={setActiveSearch}/>}
        {isActiveMessage && <MessageLists />}
        {isActiveNotify && <NotificationModal setActiveNotify={setActiveNotify} /> }

        
      </div>
    </>
  );
};

export default Sidebar;
