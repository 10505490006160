import React from 'react'

const MessagePage = () => {
  return (
    <>
        Message Page
    </>
  )
}

export default MessagePage