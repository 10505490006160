import React from "react";
import SearchArea from "./search-area/SearchArea";
import RecentUsers from "../recent-users/RecentUsers";

const SearchBar = ({ setActiveSearch }) => {
  return (
    <>
      <div className="searchBarSec">
        <div className="title">
          <h4>
            <i
              className="ri-arrow-left-s-line"
              onClick={() => setActiveSearch(false)}
            ></i>
            Search
          </h4>
        </div>
        <SearchArea />
        <div className="recentTitle">
          <h6>Recent</h6>
          <button type="button">Clear All</button>
        </div>
        <RecentUsers />
      </div>
    </>
  );
};

export default SearchBar;
