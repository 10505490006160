import React from "react";
import { Link } from "react-router-dom";

const NotificationCard = ({ data, handleRemove }) => {
  const { id, user_name, user_img, user_text } = data;
  return (
    <>
      <div className="searchItem">
        <Link to={"#"}>
          <div className="searchUserDetail">
            <div className="userFigure">
              <img src={user_img} alt={user_name} className="img-fluid" />
            </div>
            <div className="userText">
              <h6>{user_name}</h6>
              <p>
                {user_text} <span className="time"> 2d </span>
              </p>
            </div>
          </div>
          <div className="cancelUser1">
            <button className="btn btn-warning" type="button">
              Accept
            </button>
            <button className="btn btn-primary" type="button">
              Cancel
            </button>
          </div>
        </Link>
      </div>
    </>
  );
};

export default NotificationCard;
