import React from 'react'

const FollowItem = ({data}) => {
    const {name, userID, useImg} = data;
  return (
    <>
        <div className="followItem">
            <div className="followImg">
                <img src={useImg} alt={name} className='img-fluid' />
            </div>
            <div className="followText">
            <h5>{name}</h5>
            <p>{userID}</p>
            </div>
            <div className="followBtn">
                <button type='btn btn-primary'>Follow</button>
            </div>
        </div>
    </>
  )
}

export default FollowItem