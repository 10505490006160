import React from 'react'
import Setting from '../../components/setting/Setting'

const SettingPage = () => {
  return (
    <>
        <Setting />
    </>
  )
}

export default SettingPage