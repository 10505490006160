import React, { useState } from "react";
import logo from "../../assets/img/logo/logo.png";
import BeginModal from "./begin-modal/BeginModal";

const Register = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="registerSec">
        <div className="registerCard">
          <div className="topRegister">
            <div className="brand">
              <img src={logo} alt="Flow Time" className="img-fluid" />
            </div>
            <p>Sign up to see photos and videos from your friends.</p>
            <div className="facebookConnect">
              <button type="button">
                <i className="ri-facebook-box-fill"></i> Login with Facebook
              </button>
            </div>
          </div>
          <div className="orOption">
            <span>or</span>
          </div>
          <div className="loginBody">
            <div className="welcome">Hello! Register to get started</div>
            <form className="loginFormField">
              <div className="form-group mb-3">
                <input
                  type="name"
                  placeholder="Enter your name"
                  className="form-control"
                  autoComplete="off"
                />
              </div>

              <div className="form-group mb-3">
                <input
                  type="user"
                  placeholder="Enter your user name"
                  className="form-control"
                  autoComplete="off"
                />
              </div>

              <div className="form-group mb-3">
                <input
                  type="mail"
                  placeholder="Enter your mail"
                  className="form-control"
                  autoComplete="off"
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="phone"
                  placeholder="Enter phone number"
                  className="form-control"
                  autoComplete="off"
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="password"
                  placeholder="Enter your password"
                  className="form-control"
                  autoComplete="off"
                  name="password"
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="password"
                  placeholder="Enter your Confirm password"
                  className="form-control"
                  autoComplete="off"
                  name="password"
                />
              </div>
              <div className="forgotPass form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Remember me
                </label>
              </div>

              {/* {isError && <p className="text-danger mb-1">login Fail ! </p>}
                {isSuccess && (
                  <p className="text-green mb-1">login Successfully !</p>
                )} */}
              <button className="btn btn-primary enterLogin" type="button" variant="primary" onClick={handleShow}>
                Sign up
                {/* {isLoading && (
                    <Spinner style={{ marginLeft: "7px" }} animation="border" />
                  )} */}
              </button>
            </form>
          </div>
          <div className="registerNow">
            <p>
              Already Have An Account?{" "}
              <a href="/login" >
                Login now
              </a>
            </p>
          </div>
        </div>
      </div>

      <BeginModal data={{show, handleClose}} />
    </>
  );
};

export default Register;
