import React, { useState } from "react";
import MessageCard from "./message-card/MessageCard";
import message1 from "../../../../../assets/img/message/message1.png";
import message2 from "../../../../../assets/img/message/message2.png";
import message3 from "../../../../../assets/img/message/message3.png";
import message4 from "../../../../../assets/img/message/message4.png";
import message5 from "../../../../../assets/img/message/message5.png";

const searchUserData = [
  {
    id: 1,
    user_name: "Anna Bella",
    user_img: message1,
    user_text: "Great! thank you so much",
  },
  {
    id: 2,
    user_name: "Anna Bella",
    user_img: message2,
    user_text: "Great! thank you so much",
  },
  {
    id: 3,
    user_name: "Anna Bella",
    user_img: message3,
    user_text: "Great! thank you so much",
  },
  {
    id: 4,
    user_name: "Anna Bella",
    user_img: message4,
    user_text: "Great! thank you so much",
  },
  {
    id: 5,
    user_name: "Anna Bella",
    user_img: message5,
    user_text: "Great! thank you so much",
  },
];

const MessageUsers = () => {
  const [data, setData] = useState(searchUserData);
  const handleRemove = (index) => {
    const filterData = data.filter((item) => {
      return item.id !== index;
    });
    setData(filterData);
  };
  return (
    <>
      <div className="searchUserSec">
        {data &&
          data.map((item) => {
            return (
              <MessageCard
                key={item.id}
                data={item}
                handleRemove={handleRemove}
              />
            );
          })}
      </div>
    </>
  );
};

export default MessageUsers;
