import React from 'react';
import { Link } from "react-router-dom";
import share from "../../../../assets/img/explore/share.png"

const ExploreItem = ({data}) => {
    const {userImg, name, user, likes, url} = data;
  return (
    <>
        <div className="exploreItem">
              <div className="exploreHeader">
                <div className="user">
                  <img
                    src={userImg}
                    alt={name}
                    className="img-fluid"
                  />
                  <p>{user}</p>
                </div>
                <div className="bar">
                  <i className="ri-more-2-line"></i>
                </div>
              </div>
              <div className="exploreBody">
                <div className="posterImg">
                  <img
                    src={url}
                    alt={name}
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="exploreFooter">
                <div className="topContent">
                  <div className="share">
                    <ul>
                      <li>
                        <i className="ri-heart-3-line"></i>
                      </li>
                      <li>
                        <i className="ri-chat-3-line"></i>
                      </li>
                      <li>
                        {/* <img src={share} alt="Share" /> */}
                        <i className="ri-share-forward-line"></i>
                      </li>
                    </ul>
                  </div>
                  <div className="save">
                  <i className="ri-bookmark-line"></i>
                  </div>
                </div>
                <div className="middleContent">
                  <span className="likes">{likes}K likes</span>
                  <p>
                    <strong className="me-2">{user}</strong>
                    {name}
                  </p>
                </div>
                <div className="bottomContent">
                  <Link to="/">View All 10 Comments</Link>
                  <p className="time">2 days Ago</p>
                </div>
              </div>
            </div>
    </>
  )
}

export default ExploreItem