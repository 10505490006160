import React from "react";
import user from "../../../assets/img/explore/user.png";
import poster from "../../../assets/img/explore/poster.png";
import ExploreItem from "./explore-item/ExploreItem";

const exploreData = [
  {
    id: 1,
    name: "Lorem Ipsum is simply dumm",
    url: poster,
    wishlist: "36525",
    user: "John Smith",
    userImg: user,
    likes: "123",
  },
  {
    id: 2,
    name: "Lorem Ipsum is simply dumm",
    url: poster,
    wishlist: "36525",
    user: "David Warner",
    userImg: user,
    likes: "556",
  },
  {
    id: 3,
    name: "Lorem Ipsum is simply dumm",
    url: poster,
    wishlist: "36525",
    user: "Hardik",
    userImg: user,
    likes: "226",
  }
];

const Explore = () => {
  return (
    <>
      <div className="exploreSec">
          <div className="exploreCard">
            {exploreData &&
              exploreData.map((item) => {
                return <ExploreItem key={item.id} data={item} />;
              })}
          </div>
      </div>
    </>
  );
};

export default Explore;
